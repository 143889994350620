<template>
  <div class="sensorDetail">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false"
               @close="closeDio('dioform')">
      <div>
        <el-tabs v-model="tabsActive" @tab-click="tabsClick" type="border-card">
          <el-tab-pane label="数据信息" name="1">
            <el-form class="contDio" ref="dioform" :model="inform" label-width="100px" size="small">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="">
                    <el-image fit="contain" :src="getIcon(inform.senseType,inform.isRelay)"></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="设备名称:">{{ inform.deviceName }}</el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="设备编号:">{{ inform.model }}</el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="所在场所:">
                    {{ inform.homeName }}
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="所在位置:">

                  </el-form-item>
                </el-col>
                <template v-if="inform.targetValueList && inform.targetValueList.length > 0">
                  <el-col :span="12" v-for="(item,index) in inform.targetValueList" :key="getUUID()">
                    <el-form-item :label="`${item.name}:`">{{ item.value }}{{ item.unit }}</el-form-item>
                  </el-col>
                </template>
              </el-row>
            </el-form>
            <div class="chart" ref="datachart"></div>
          </el-tab-pane>
          <!--          <el-tab-pane label="操作记录" name="2">
                      <el-form :model="logKeyWord" label-width="100px" inline @submit.native.prevent size="small">
                        <el-form-item label="操作时间:" prop="time">
                          <el-date-picker v-model="logKeyWord.time" value-format="yyyy-MM-dd"
                                          :default-time="['00:00:00', '23:59:59']" @change="deviceLogs(1)" type="daterange"
                                          range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"/>
                        </el-form-item>
                        <el-form-item label="">
                          <el-button type="primary" size="small" @click="deviceLogs(1)">搜索</el-button>
                        </el-form-item>
                      </el-form>
                      <el-table class="tableCont" :data="logData" stripe :height="400" style="width:100%">
                        <el-table-column type="index" label="序号" width="60"/>
                        <el-table-column prop="content" label="日志说明" show-overflow-tooltip/>
                        <el-table-column :formatter="timeFormat" label="操作时间" width="200" show-overflow-tooltip/>
                      </el-table>
                      <div class="pageDiv">
                        <el-pagination
                            :current-page="curr2"
                            :page-size="limits2"
                            background
                            layout="total,sizes, prev, pager, next, jumper"
                            :page-sizes="[10, 20, 50, 100,200]"
                            :total="total2"
                            @size-change="sizeChange2"
                            @current-change="currChange2"
                        />
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="告警记录" name="3">
                      <el-form :model="alarmKeyWord" label-width="100px" inline @submit.native.prevent size="small">
                        <el-form-item label="分类:" prop="type">
                          <el-select v-model="alarmKeyWord.type" clearable placeholder="选择分类" class="w100"
                                     @change="deviceAlarmList(1)">
                            <el-option v-for="(item,index) in dicObj.alarmData" :key="index" :label="item.dictName"
                                       :value="item.dictValue"></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="告警时间:" prop="time">
                          <el-date-picker v-model="alarmKeyWord.time" value-format="yyyy-MM-dd"
                                          :default-time="['00:00:00', '23:59:59']" clearable @change="deviceAlarmList(1)"
                                          type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"/>
                        </el-form-item>
                        <el-form-item label="">
                          <el-button type="primary" size="small" @click="deviceAlarmList(1)">搜索</el-button>
                        </el-form-item>
                      </el-form>
                      <el-table class="tableCont" :data="alarmData" stripe :height="400" style="width:100%">
                        <el-table-column type="index" label="序号" width="60"/>
                        <el-table-column prop="alarmTypeName" label="告警类型" width="100"/>
                        <el-table-column prop="content" label="告警说明" show-overflow-tooltip/>
                        <el-table-column :formatter="timeFormat2" label="操作时间" width="200" show-overflow-tooltip/>
                      </el-table>
                      <div class="pageDiv">
                        <el-pagination
                            :current-page="curr3"
                            :page-size="limits3"
                            background
                            layout="total,sizes, prev, pager, next, jumper"
                            :page-sizes="[10, 20, 50, 100,200]"
                            :total="total3"
                            @size-change="sizeChange3"
                            @current-change="currChange3"
                        />
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="安全参数" name="4">
                      <el-table
                          class="tableCont"
                          :data="safeParamsList"
                          style="width: 100%"
                          size="medium"
                          max-height="250">
                        <el-table-column
                            fixed
                            prop="paramsName"
                            label="类型"
                            width="120">
                        </el-table-column>
                        <el-table-column
                            label="故障阈值"
                            width="120">
                          <template slot-scope="scope">
                              <span>{{ scope.row.alarm }}{{ scope.row.paramsUnit }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                            label="是否生效"
                            width="120">
                          <template slot-scope="scope">
                            <el-switch v-model="scope.row.alarmSwitch==1" readonly></el-switch>
                          </template>
                        </el-table-column>

                        <el-table-column
                            label="预警阈值"
                            width="120">
                          <template slot-scope="scope">
                            <span>{{ scope.row.fault }}{{ scope.row.paramsUnit }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                            label="是否生效"
                            width="120">
                          <template slot-scope="scope">
                            <el-switch v-model="scope.row.faultSwitch==1" readonly></el-switch>
                          </template>
                        </el-table-column>
                        <el-table-column
                            label="动作延迟"
                            width="120">
                          <template slot-scope="scope">
                            <span>{{ scope.row.actionDelay }}秒</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                            label="自恢复值"
                            width="130">
                          <template slot-scope="scope">
                            <span>{{ scope.row.recover }}{{ scope.row.paramsUnit }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                            label="自恢复延迟"
                            width="120">
                          <template slot-scope="scope">
                            <span>{{ scope.row.recoverDelay }}秒</span>
                          </template>
                        </el-table-column>
          &lt;!&ndash;              <el-table-column
                            fixed="right"
                            label="操作"
                            width="120">
                          <template slot-scope="scope">
                            <el-button
                                @click.native.prevent="deleteRow(scope.$index, safeParamsList)"
                                type="text"
                                size="small">
                              移除
                            </el-button>
                          </template>
                        </el-table-column>&ndash;&gt;
                      </el-table>
                    </el-tab-pane>-->
        </el-tabs>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {apiUrl, globalStr} from '@/assets/js/api'
import qs from 'qs'
import {delChildren, getUUID, formatTime} from '@/utils/utils'

let d_icon1 = require('@/assets/images/yan_gan.png');
let d_icon2 = require('@/assets/images/ipc.png');
let d_icon3 = require('@/assets/images/wen_shi_du.png');
let d_icon4 = require('@/assets/images/shui_jin.png');
let d_icon5 = require('@/assets/images/IO.png');
//百度坐标与gps坐标转换方法
var vm;
export default {
  props: ['dicObj'],
  data() {
    return {
      isDio: false,//弹窗类型
      dioTit: '',//弹窗标题
      dioType: '',//弹窗类型
      dioWidth: '',//弹窗宽度

      tabsActive: '1',//tabs

      //form信息
      inform: {},

      logKeyWord: {
        time: '',
      },
      logData: [],//操作记录
      //分页
      curr2: 1,//当前页
      limits2: 10,//每页容量
      total2: 0,//数据总数

      alarmKeyWord: {
        type: '',
        time: '',
      },
      alarmData: [],//告警记录
      //分页
      curr3: 1,//当前页
      limits3: 10,//每页容量
      total3: 0,//数据总数
      safeParamsList: [],//设备安全参数
      nenghaoBox:null,
    }
  },
  created() {
    vm = this
  },
  filters: {
    //状态过滤器
    statesFilter(val, type) {
      //在线状态
      if (type == '1') {
        let result = vm.dicObj.statusData.filter(item => item.dictValue == val)
        return result[0].dictName
      }
      //连接状态
      if (type == '2') {
        let result = vm.dicObj.connectData.filter(item => item.dictValue == val)
        return result[0].dictName
      }
      //告警状态
      if (type == '3') {
        let result = vm.dicObj.alarmData.filter(item => item.dictValue == val)
        return result[0].dictName
      }
      //合闸状态
      if (type == '4') {
        let result = vm.dicObj.tripData.filter(item => item.dictValue == val)
        return result[0].dictName
      }
    },
  },
  //方法
  methods: {
    //初始化
    init(type, item) {
      vm.dioType = type
      vm.dioWidth = '840px'
      vm.isDio = true
      vm.dioTit = '详情'
      vm.inform = item
      vm.statisticsEnergy();
    },
    getIcon(type, isRelay) {
      if (isRelay == '1') {
        return d_icon5
      } else {
        if (type == '0') {//温湿度
          return d_icon3
        } else if (type == '1') {//烟感
          return d_icon1
        } else if (type == '2') {//水浸
          return d_icon4
        } else if (type == '3') {//火焰
          return d_icon2
        }
      }
    },
    //获取uuid
    getUUID() {
      return getUUID()
    },
    initChart(chartData) {
      let nameArr=[],timeArr = [], seriesData = [];
      timeArr = chartData[0].times
      for (let i=0;i<chartData.length;i++) {
        const data = chartData[i];
        nameArr.push(data.name+"("+data.unit+")");
        let obj = {
          name: data.name+"("+data.unit+")",
          type: 'line',
          smooth: true,
          lineStyle: {
            width: 1
          },
          areaStyle: i==0 ? {
            color: new vm.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(137, 189, 27, 0.3)'
            }, {
              offset: 0.8,
              color: 'rgba(137, 189, 27, 0)'
            }], false),
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowBlur: 10
          } : {
            color: new vm.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(0, 136, 212, 0.3)'
            }, {
              offset: 0.8,
              color: 'rgba(0, 136, 212, 0)'
            }], false),
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowBlur: 10
          },
          itemStyle: {
            color: i==0 ? 'rgb(137,189,27)' : 'rgb(0,136,212)'
          },
          data: data.values
        }
        seriesData.push(obj)
      }
      vm.dataChart(nameArr,timeArr,seriesData);
    },
    //获取能耗统计
    async statisticsEnergy() {
      let dataObj = {
        'id': vm.inform.id
      }
      let res = await apiUrl.statisticTempH(qs.stringify(dataObj))
      if (res?.code == 200) {
        let chartData = res.data.chartData;
        vm.inform.targetValueList = res.data.targetValueList;
        if(chartData.length > 0) {
          vm.initChart(chartData);
        }else{
             //没有数据
            chartData= [
            {
              unit: '°C',
              times: [
                formatTime(new Date(), 'Y-M-D h:m:s')

              ],
              values: [
                0.0
              ],
              name: '温度'
            },
            {
              unit: '%',
              times: [
                formatTime(new Date(), 'Y-M-D h:m:s')
              ],
              values: [
                0.0
              ],
              name: '湿度'
            }
          ];
            vm.initChart(chartData);
        }
      } else {
        this.$message.error(res.message)
      }
    },
    //能耗折线图
    dataChart(nameArr, timeArr, seriesData) {
      if (vm.nenghaoBox) {
        vm.nenghaoBox?.dispose()
        vm.nenghaoBox = null
      }
      vm.$nextTick(() => {
        vm.nenghaoBox = vm.$echarts.init(vm.$refs.datachart);
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              lineStyle: {
                color: '#57617B'
              }
            }
          },
          legend: {
            icon: 'rect',
            itemWidth: 14,
            itemHeight: 5,
            itemGap: 13,
            data: nameArr,
            right: '4%',
            top: '13%',
            textStyle: {
              fontSize: 12,
              color: '#fff'
            }
          },
          grid: {
            left: '1%',
            right: '3%',
            bottom: '3%',
            top: '25%',
            containLabel: true
          },
          visualMap: {
            type: 'continuous',//continuous:连续型，piecewise:分段型
            show: false,
            min: 0, // 映射范围最小值
            max: 30, // 映射范围最大值
            // pieces:[
            //   {min: 0, max: 10},
            //   {min: 10, max: 15},
            //   {min: 15},
            // ],
            inRange: {
              color: ['green', 'yellow', 'red'],
              // symbolSize: [0,10,20]
            },
            seriesIndex: 0
          },
          xAxis: [{
            type: 'category',
            boundaryGap: false,
            axisLabel: {
              color: '#fff',
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,.15)',
              }
            },
            data: timeArr,
          }],
          yAxis: [{
            name: "",
            type: 'value',
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#fff',
              }
            },
            axisLabel: {
              margin: 10,
              fontSize: 14
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(255,255,255,.15)',
                type: 'dashed'
              }
            }
          }],
          series: seriesData
        };
        vm.nenghaoBox.setOption(option)
      })
    },
    //过滤时间
    timeFormat(row, column) {
      return row.createTime ? formatTime(row.createTime, 'Y-M-D h:m:s') : '-'
    },
    timeFormat2(row, column) {
      console.log(row.createTime);
      return row.createTime ? `${row.createTime}` : '-'
    },
    //tabs切换
    tabsClick(tab, event) {
      if (this.tabsActive == '2') {
        this.deviceLogs(1)
      } else if (this.tabsActive == '3') {
        this.deviceAlarmList(1)
      } else if (this.tabsActive == '4') {
        this.deviceSafeParams();
      }
    },
    async deviceSafeParams() {
      const res = await apiUrl.deviceSafeParams(vm.inform.id);
      if (res?.code == 200) {
        vm.safeParamsList = res.data;
      } else {
        vm.$message.error(res.message)
      }
    },
    //获取操作记录
    async deviceLogs(_curr, _limits) {
      let dataObj = {
        'homeId': vm.inform.homeId,
        'devId': vm.inform.id,
        'pageNo': _curr ? _curr : vm.curr2,
        'pageSize': _limits ? _limits : vm.limits2,
        'startTime': vm.logKeyWord.time ? vm.logKeyWord.time[0] : '',
        'endTime': vm.logKeyWord.time ? vm.logKeyWord.time[1] : '',
      }
      const res = await apiUrl.deviceLogs(dataObj)
      if (res?.code == 200) {
        vm.logData = res.data.list
        vm.total2 = parseInt(res.data.totalSize)
        vm.curr2 = _curr ? _curr : vm.curr2
      } else {
        vm.$message.error(res.message)
      }
    },
    //获取告警记录
    async deviceAlarmList(_curr, _limits) {
      let dataObj = {
        'homeId': vm.inform.homeId,
        'devId': vm.inform.id,
        'pageNo': _curr ? _curr : vm.curr3,
        'pageSize': _limits ? _limits : vm.limits3,
        'alarmCategory': vm.alarmKeyWord.type,
        'startTime': vm.alarmKeyWord.time ? vm.alarmKeyWord.time[0] : '',
        'endTime': vm.alarmKeyWord.time ? vm.alarmKeyWord.time[1] : '',
      }
      const res = await apiUrl.deviceAlarmList(dataObj)
      if (res?.code == 200) {
        vm.alarmData = res.data.list
        vm.total3 = parseInt(res.data.totalSize)
        vm.curr3 = _curr ? _curr : vm.curr3
      } else {
        vm.$message.error(res.message)
      }
    },
    //分页选择
    sizeChange2(val) {
      vm.limits2 = val
      vm.deviceLogs(vm.curr2, val)
    },
    currChange2(val) {
      vm.curr2 = val
      vm.deviceLogs(val)
    },
    //分页选择
    sizeChange3(val) {
      vm.limits3 = val
      vm.deviceAlarmList(vm.curr3, val)
    },
    currChange3(val) {
      vm.curr3 = val
      vm.deviceAlarmList(val)
    },
    //关闭弹窗
    closeDio(formEl) {
      vm.inform = {
        id: '',
        devName: '',//设备名称
        devNo: '',//设备编码
        devType: '',//设备类型
        homeId: '',//场所id
        icon: '',//icon
        ip: '',//设备IP
        states: [],
        attributes: [],
      }
      vm.tabsActive = '1'
      vm.logKeyWord = {
        time: '',
      }
      vm.logData = [];//操作记录
      vm.alarmData = [];//告警记录
      //分页
      vm.curr2 = 1;//当前页
      vm.limits2 = 10;//每页容量
      vm.total2 = 0;//数据总数
      vm.curr3 = 1;//当前页
      vm.limits3 = 10;//每页容量
      vm.total3 = 0;//数据总数
      vm.alarmKeyWord = {
        type: '',
        time: '',
      }
      vm.isDio = false
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
  }
}
</script>
<style lang='scss'>
.chart{
  height: 300px;
}
.sensorDetail {
  .contDio {
    max-height: 500px;
    overflow: auto;

    .el-form-item--small.el-form-item {
      margin-bottom: 0px;
    }

    .el-image {
      width: 100px;
      height: 150px;
    }

    .name {
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd;
      font-size: 16px;
      color: #fff;
    }
  }
}
</style>