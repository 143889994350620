<template>
  <div class="sensorSwitch">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio">
      <div class="contDio">
        <el-button type="primary" size="small" @click="openDio('relevance')"><i
            class="el-icon-plus"></i>关联
        </el-button>
        <div class="content">
          <div class="leftDiv">
            <div class="title">输入端</div>
            <div class="list">
              <div class="item" v-for="(item,index) in inform.inData" :key="index">
                <div class="no">{{ item.switchNumber }}路</div>
                <el-select v-model="item.connectDeviceId" placeholder="请选择输入端设备" size="small" clearable
                           @change="selChange">
                  <el-option v-for="(item,index) in deviceList" :key="index" :label="item.deviceName" :value="item.id"
                             :disabled="item.disabled"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="rightDiv">
            <div class="title">输出端</div>
            <div class="list">
              <div class="item" v-for="(item,index) in inform.outData" :key="index">
                <div class="no">{{ item.switchNumber }}路</div>
                <el-select v-model="item.connectDeviceId" placeholder="请选择输出端设备" size="small" clearable
                           @change="selChange">
                  <el-option v-for="(item,index) in deviceList" :key="index" :label="item.deviceName" :value="item.id"
                             :disabled="item.disabled"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="closeDio()" size="small">关闭</el-button>
        <el-button type="primary" size="small" @click="checkForm">提交</el-button>
          </span>

    </el-dialog>
    <!--开关关系连线关联-->
    <sensor-switch-add-edit :sourceConn="-1" :targetConn="1" ref="editSwitchRef"></sensor-switch-add-edit>
  </div>
</template>
<script>
import sensorSwitchAddEdit from './sensor-switch-add-edit';
import {apiUrl} from '@/assets/js/api'
import qs from 'qs'

var vm;
export default {
  props: ['dicObj'],
  data() {
    return {
      isDio: false,//弹窗类型
      dioTit: '',//弹窗标题
      dioType: '',//弹窗类型
      dioWidth: '',//弹窗宽度

      //form信息
      inform: {
        id: '',
        relayDeviceId: '',//IO采集ID
        inData: [],//输入数据
        outData: [],//输出数据
        senseType: '',//传感器类型
      },

      deviceList: [],//设备列表
      rules: {
        hostUrl: [
          {required: true, message: '请输入mqtt地址', trigger: 'blur'}
        ],
        username: [
          {required: true, message: '请输入mqtt账户', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入账户密码', trigger: 'blur'}
        ],
        topic: [
          {required: true, message: '', trigger: 'blur'}
        ],

        isRelay: [
          {required: true, message: '请选择是否IO采集', trigger: 'change'}
        ]
      },
    }
  },
  components: {
    sensorSwitchAddEdit
  },
  created() {
    vm = this
  },

  //方法
  methods: {
    //初始化
    async init(type, item) {
      // console.log(item);
      vm.dioType = type
      if (type == 'config') {
        vm.dioTit = 'IO采集配置';
        vm.inform.id = item.id
        vm.inform.relayDeviceId = item.id
        vm.inform.senseType = item.senseType
        await vm.deviceSenseUnbound(item.id);
        vm.getDeviceRelay(item.id);
      }
      vm.dioWidth = '840px'
      vm.isDio = true
    },
    //下拉选择
    selChange(val) {
      // console.log(val);
      let idArr = []
      for (var i in this.inform.inData) {
        if (this.inform.inData[i].connectDeviceId) idArr.push(this.inform.inData[i].connectDeviceId)
      }
      for (var i in this.inform.outData) {
        if (this.inform.outData[i].connectDeviceId) idArr.push(this.inform.outData[i].connectDeviceId)
      }
      this.deviceList = this.deviceList.map(item => ({
        ...item, // 使用扩展运算符来复制当前对象  
        disabled: idArr.includes(item.id) // 如果id在idArr中存在，则disable为true，否则为false  
      }));
    },
    //获取未绑定的设备
    async deviceSenseUnbound(id) {
      let dataObj = {
        'id': id,
      }
      const res = await apiUrl.deviceSenseUnbound(qs.stringify(dataObj))
      if (res?.code == 200) {
        if (res.data?.length > 0) {
          for (var i in res.data) {
            res.data[i].disabled = false
          }
        }
        this.deviceList = res.data
      } else {
        this.$message.error(res.message)
      }
    },
    //获取IO采集开关列表
    async getDeviceRelay(id) {
      let dataObj = {
        'id': id,
      }
      const res = await apiUrl.getDeviceRelay(qs.stringify(dataObj))
      if (res?.code == 200) {
        this.inform.inData = res.data.inputArray
        this.inform.outData = res.data.outputArray
        //下面两个for是把已绑定的设备放到下拉设备列表里面去
        for (var i in res.data.inputArray) {
          if (res.data.inputArray[i].connectDeviceId) {
            let obj = {
              'id': res.data.inputArray[i].connectDeviceId,
              'deviceName': res.data.inputArray[i].connectDeviceName,
              'disabled': true,
            }
            this.deviceList.push(obj)
          }
        }
        for (var i in res.data.outputArray) {
          if (res.data.outputArray[i].connectDeviceId) {
            let obj = {
              'id': res.data.outputArray[i].connectDeviceId,
              'deviceName': res.data.outputArray[i].connectDeviceName,
              'disabled': true,
            }
            this.deviceList.push(obj)
          }
        }
      } else {
        this.$message.error(res.message)
      }
    },
    //打开弹窗
    openDio(type, item) {
      if (type == 'relevance') {
        vm.$refs.editSwitchRef.init(type, this.inform.relayDeviceId);//关联
      }

    },
    //提交
    checkForm() {
      this.updateDeviceRelay()
    },
    //更新
    async updateDeviceRelay() {
      let arr = []
      for (var i in this.inform.inData) {
        let obj = {
          'switchNumber': this.inform.inData[i].switchNumber,
          'switchType': this.inform.inData[i].switchType,
          'relayDeviceId': this.inform.inData[i].relayDeviceId,
          'connectDeviceId': this.inform.inData[i].connectDeviceId,
        }
        arr.push(obj)
      }
      for (var i in this.inform.outData) {
        let obj = {
          'switchNumber': this.inform.outData[i].switchNumber,
          'switchType': this.inform.outData[i].switchType,
          'relayDeviceId': this.inform.outData[i].relayDeviceId,
          'connectDeviceId': this.inform.outData[i].connectDeviceId,
        }
        arr.push(obj)
      }
      const res = await apiUrl.updateDeviceRelay(arr);
      if (res?.code == 200) {
        this.$message.success('更新成功！')
        this.closeDio()
      } else {
        this.$message.error(res.message)
      }
    },
    //关闭弹窗
    closeDio(formEl) {
      vm.inform = {
        id: '',
        relayDeviceId: '',//IO采集ID
        inData: [],//输入数据
        outData: [],//输出数据
        senseType: '',
        mqtt: {
          id: '',
          topic: '',
          hostUrl: '',
          username: '',
          password: '',
          deviceId: '',
          deviceType: '',
          deviceSubType: ''
        }
      }
      vm.deviceList = []//设备列表
      vm.isDio = false
    },

  }
}
</script>
<style lang='scss'>
.sensorSwitch {
  .contDio {
    color: #fff;

    .content {
      display: flex;
      justify-content: space-between;
      position: relative;

      .leftDiv, .rightDiv {
        width: 45%;
        flex-shrink: 0;

        .list {
          max-height: 500px;
          overflow: auto;

          .item {
            // border: 1px solid #ddd;
            border-radius: 4px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            padding: 5px;
            background: #112547;
            justify-content: space-around;

            .el-input__inner {
              background-color: #031e44;
              color: #00c6ff;
              border-color: #1d4975;

              &:hover {
                border-color: #1d4975;
              }

              &::placeholder {
                color: #00c6ff;
              }
            }

            .no {
              margin-right: 10px;
            }

            .el-select {
              z-index: 1;
            }
          }
        }

        .title {
          text-align: center;
          font-size: 16px;
          padding: 10px 0;
        }
      }
    }
  }
}
</style>