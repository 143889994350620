<template>
  <div class="sensorSwitchAddEdit" v-if="isDio">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="tips fs12 error mb10"><em class="mr10">*</em>从左侧列表拖动连线到右侧列表，双击删除当前连线！</div>
      <div class="switchCont">
      <div class="contDio" id="plumbContent">
        <div class="leftDiv">
          <div class="title">输入端</div>
          <div class="list" id="leftBottom">
            <div class="item" v-for="(item,index) in inform.inData" :key="index" name="source" :id="item.id">
              <div class="no">{{item.switchNumber}}路</div>
              <div class="name">{{ item.connectDeviceName }}</div>
            </div>
          </div>
        </div>
        <div class="rightDiv">
          <div class="title">输出端</div>
          <div class="list" id="rightBottom">
            <div class="item" v-for="(item,index) in inform.outData" :key="index" name="target" :id="item.id">
              <div class="no">{{item.switchNumber}}路</div>
              <div class="name">{{ item.connectDeviceName }}</div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api'
import qs from 'qs'
import {delChildren} from '@/utils/utils'
var vm;
export default {
  props:{
    sourceConn:{
      type:Number,
      default(){
        return -1
      }
    },
    targetConn:{
      type:Number,
      default(){
        return -1
      }
    },
  },
  data() {
    return {
      isDio:false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        relayDeviceId:'',//IO采集ID
        inData:[],//输入数据
        outData:[],//输出数据
      },

      plumbins: null, // 缓存实例化的jsplumb对象
      connection: [],
      connectionList: [], //手动维护一个关系
    }
  },
  created(){
    vm = this
    
  },
  mounted(){
    
  },

  //方法
  methods: {
    //初始化
    async init (type,id){
      // console.log(item);
      vm.dioType = type
      vm.dioWidth = '740px'
      vm.isDio = true
      await vm.getDeviceRelay(id)
      await vm.getRelation(id)
      vm.inform.relayDeviceId = id
      vm.dioTit = '关联输入输出端口'
      vm.$nextTick(()=>{
      
      //   初始化实例
      vm.$jsPlumb.ready(() => {
        vm.plumbins = vm.$jsPlumb.getInstance({
          Container: "plumbContent", // 选择器id
          Connector: ["Straight"], //直线连接
          RenderMode : "svg",//默认渲染模式
        });
        // 双击删除连线
        vm.plumbins.bind("dblclick", (connection, originalEvent) => {
          // console.log(connection);
          vm.plumbins.deleteConnection(connection);
        });
        vm.plumbins.bind('beforeDrop', function (info) {
          console.log('连接前',info);
          return true
        })
        vm.plumbins.bind("connection", (conn, originEvent) => {
          // 数据存入
          let obj = {};
          obj.sourceId = conn.sourceId;
          obj.targetId = conn.targetId;
          console.log('连线数据：',obj);
          vm.repaintPlumb()

        });
        vm.plumbins.batch(() => {
          vm.inform.inData.forEach((item, index) => {
            vm.initNode(item.id, "source");
          });
          vm.inform.outData.forEach((item, index) => {
            vm.initNode(item.id, "target");
          });
        });
        // 添加列表支持滚动
        vm.plumbins.addList(leftBottom);
        vm.plumbins.addList(rightBottom);
      });
      vm.repaintConnetion();
      // 每次页面大小改变重新绘画连线
      window.addEventListener("resize", vm.repaintPlumb);
      })
    },
    //获取IO采集开关列表
    async getDeviceRelay(id){
      let dataObj = {
        'id':id,
      }
      const res = await apiUrl.getDeviceRelay(qs.stringify(dataObj))
      if(res?.code==200){
        this.inform.inData = res.data.inputArray
        this.inform.outData = res.data.outputArray
      }else{
        this.$message.error(res.message)
      }
    },
    //获取IO采集关联
    async getRelation(id){
      let dataObj = {
        'id':id,
      }
      const res = await apiUrl.getRelation(qs.stringify(dataObj))
      if(res?.code==200){
        let arr = []
        if(res.data?.length>0){
          for(var i in res.data){
            let str = this.inform.inData?.find((item)=>{return item.switchNumber == res.data[i].switchInput})
            let str2 = this.inform.outData?.find((item)=>{return item.switchNumber == res.data[i].switchOutput})
            let obj = {
              'sourceId':str?.id,
              'targetId':str2?.id,
            }
            arr.push(obj)
          }
        }
        this.connectionList = arr
      }else{
        this.$message.error(res.message)
      }
    },
    //校验form
    checkForm (){
      let arr = vm.plumbins.getConnections()
      // console.log('所有的连线',arr);
      // return 
      if(arr.length>0){
        let newArr = []
        for(var i in arr){
          let str = this.inform.inData?.find((item)=>{return item.id == arr[i].sourceId})
          let str2 = this.inform.outData?.find((item)=>{return item.id == arr[i].targetId})
          let obj = {
            'relayDeviceId':this.inform.relayDeviceId,
            'switchInput':str?.switchNumber,
            'switchOutput':str2?.switchNumber,
          }
          newArr.push(obj)
        }
        vm.updateRelation(newArr)
      }else{
        this.$message.warning('请连接节点')
      }
    },
    async updateRelation(newArr){
      const res = await apiUrl.updateRelation(newArr)
      if(res?.code==200){
        this.$message.success('关联成功')
        this.closeDio()
      }else{
        this.$message.error(res.message)
      }
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        // vm.$refs[formEl].resetFields()
      // })
      this.inform = {
        id:'',
        relayDeviceId:'',//IO采集ID
        inData:[],//输入数据
        outData:[],//输出数据
      }

      this.plumbins = null; // 缓存实例化的jsplumb对象
      this.connection= [];
      this.connectionList= []; //手动维护一个关系
      vm.isDio = false
    },
    
    initNode(id, type) {
      // 初始化点使其可以连接
      const ins = this.plumbins;
      const elements = document.getElementById(id);
      if (type === "source") {
        ins.makeSource(elements, {
          // 锚点
          anchor: [1, 0.5, 0, 0],
          allowLoopback: false,
          maxConnections: vm.sourceConn, //一对一就把maxConnections设置为1，一对多就设置为-1
          //   锚点样式
          paintStyle: {
            fill: "skyblue",
            strokeWidth: 2,
            radius: 4,
          },
          hoverPaintStyle: { fill: "red" },
          //   连线样式
          connectorStyle: {
            outlineStroke: "skyblue",
            strokeWidth: 2,
          },
          connectorHoverStyle: {
            strokeWidth: 4,
            stroke: "red",
            outlineStroke: "red",
          },
        });
      } else {
        ins.makeTarget(elements, {
          anchor: [0, 0.5, 1, 0], // 锚点
          //   锚点样式
          paintStyle: {
            strokeWidth: 2,
            radius: 20,
          },
          allowLoopback: false,
          maxConnections: vm.targetConn, //一对一就把maxConnections设置为1，一对多就设置为-1
          paintStyle: {
            fill: "skyblue",
            strokeWidth: 2,
            radius: 4,
          },
          endpointHoverStyle: { fill: "red" },
        });
      }
    },
    setjsPlumb(sourceFlag, targetFlag) {
      const source = document.getElementsByName("source");
      const target = document.getElementsByName("target");

      this.plumbins.setSourceEnabled(source, sourceFlag);
      this.plumbins.setTargetEnabled(target, targetFlag);
      this.plumbins.setDraggable(source, true); // 是否支持拖拽
      this.plumbins.setDraggable(target, true); // 是否支持拖拽
    },
    //重新绘制
    repaintPlumb() {
      this.plumbins.repaintEverything();
    },
    //重新绘制连线
    repaintConnetion(connetionList) {
      if (this.connectionList.length != 0) {
        let connect = this.connectionList.concat([]);
        console.log('connect',connect);
        this.$nextTick(() => {
          // 根据commonJS中的列表重新划线
          connect.forEach((item) => {
            this.plumbins.connect({
              source: item.sourceId,
              target: item.targetId,
            });
          });
        });
      }
    },
    // 保存连线关系
    getConnetion() {
      this.connection = this.plumbins.getConnections();
    },
  }
}
</script>
<style lang='scss'>
.sensorSwitchAddEdit{
  .switchCont{
    position: relative;
    height: 500px;
    overflow: auto;
  }
  .contDio{
    display: flex;
    justify-content: space-between;
    user-select:none;
    position: absolute;
    width: 100%;
    // height: 1000px;
    left: 0;
    top: 0;
    // overflow: auto;
    .leftDiv,.rightDiv{
      width: 45%;
      flex-shrink: 0;
      .list{
        // height:200px;
        // overflow-y:auto;
        position: relative;
        .item{
          // border: 1px solid #ddd;
          border-radius: 4px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          padding:2px 5px;
          background: #112547;
          cursor: pointer;
          .no{
            margin-right: 10px;
          }
        }
      }
      .title{
        text-align: center;
        font-size: 16px;
      }
    }
  }
}
</style>